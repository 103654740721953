<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="媒介列表" />
			<table class="data_ls">
				<thead>
					<tr>
						<td>订单号</td>
						<td>套餐名</td>
						<td>金额</td>
						<td>已支付</td>
						<td>优惠券</td>
						<td>备注</td>
						<td>操作</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>N2023112099519910</td>
						<td>电商引流30家媒介套餐</td>
						<td>2000.00</td>
						<td>0.00</td>
						<td>1</td>
						<td></td>
						<td style="color: #FF0000;">删除</td>
					</tr>
				</tbody>
			</table>
			<div class="details_box">
				<Title title="订单基础信息" />
				<div class="details_ct_box flex">
					<p class="details_ct_title">宣传标题:{{ $store.state.FillForm.title }}</p>
					<p class="active" @click="goDetails">【点击查看内容】</p>
				</div>
			</div>
			<div class="order_box">
				<Title title="订单结算" />
				<div class="order_ct_box">
					<p>订单编号: </p>
					<p>下单时间:</p>
					<p>订单总额:</p>
					<p>状态:</p>
					<p>支付详情:</p>
				</div>
			</div>
		</div>

		<SetMenuButNav />
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import SetMenuButNav from '../../components/SetMenuButNav.vue'
import { mapState } from 'vuex'
export default {
	mounted() {
		// console.log(this.$store.state.FillForm)
		this.listFn()
	},
	computed: {
		...mapState(['FillForm', 'shopObj']),
	},
	components: {
		Title,
		SetMenuButNav,
	},
	data() {
		return {
			title: '',
			fullscreenLoading: false,
		}
	},
	methods: {
		goDetails() {
			this.$router.back()
			// let routeUrl = this.$router.resolve({
			// 	path: '/TextDetails',
			// 	query: {
			// 		content: this.$store.state.form.content,
			// 	},
			// })
			// window.open(routeUrl.href, '_blank')
		},
		listFn() {
			

		}
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/ManuscriptDetails.scss';
</style>